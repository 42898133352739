import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	connect() {
	}

	toggle({ params: { target } }) {
		const element = document.querySelector(target);
		if (!element) {
			console.warn(`Element with selector "${target}" not found`);
			return;
		}

		element.setAttribute(
			"disabled",
			!element.disabled
		);
	}
}
