import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	static targets = [
		'container'
	];

	static values = {
		period: Number,
		direction: String,
	}

	interval = null;

	connect() {
		const container = this.containerTarget;
		const children = [...container.children];

		// buffer children by doubling them
		for (let i = 0; i < children.length; i++) {
			container.append(children[i].cloneNode(true));
		}

		this.calibrateWidth();

		if (this.periodValue) {
			const direction = this.directionValue || 'left';
			this.startCarousel(direction, this.periodValue)
		}
	}

	calibrateWidth() {
		const container = this.containerTarget;
		const children = [...container.children];

		if (children.length > 0) {
			const width = children[0].getBoundingClientRect().width;
			// shift so that the prepend/append isn't visible
			container.style.transform = `translateX(-${width}px)`;
		}
	}

	startCarousel(direction='left', period=10) {
		direction = direction;
		period = period;
		this.interval = setInterval(() => {
			if (direction == 'left') {
				this._left();
			} else if (direction == 'right') {
				this._right();
			}
		}, 1000 * period);
	}

	stopCarousel() {
		if (this.interval) {
			console.log("stopping")
			clearInterval(this.interval);
			this.interval = null;
		}
	}

	right() {
		this.stopCarousel();
		this._right();
	}
	_right() {
		this.calibrateWidth();

		const container = this.containerTarget;
		[...container.children].forEach(child => child.classList.add("shift-right"));

		let last = container.lastElementChild;

		last.addEventListener('transitionend', () => {
			[...container.children].forEach(child => child.classList.remove("shift-right"));
			container.prepend(last);
		}, {once: true});
	}

	left() {
		this.stopCarousel();
		this._left();
	}
	_left() {
		this.calibrateWidth();

		const container = this.containerTarget;
		[...container.children].forEach(child => child.classList.add("shift-left"));

		let first = container.firstElementChild;

		first.addEventListener('transitionend', () => {
			[...container.children].forEach(child => child.classList.remove("shift-left"));
			container.append(first);
		}, {once: true});
	}
}
