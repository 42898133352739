import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	static targets = ["target"];

	static values = {
		addClassNames: Array,
		removeClassNames: Array
	}

	connect() {
		this.update();
	}

	update() {
		const elements = this.targetTargets;

		const addCls = this.addClassNamesValue;
		const removeCls = this.removeClassNamesValue;

		if (addCls.length === 0 && removeCls.length === 0) {
			console.log("no classlists");
			return;
		}

		for (let i = 0; i < elements.length; i++) {
			const el = elements[i];
			const inEl = el.querySelector("input");
			if (!inEl) {
				console.log("no input element found");
				continue;
			}

			if (inEl.checked) {
				addCls.forEach(c => { el.classList.add(c); })
				removeCls.forEach(c => { el.classList.remove(c); })
			} else {
				// inverse if unchecked
				addCls.forEach(c => { el.classList.remove(c); })
				removeCls.forEach(c => { el.classList.add(c); })
			}
		}
		//if (!element) {
		//	console.warn(`Element with selector "${target}" not found`);
		//	return;
		//}
		//
		//element.classList.toggle(className);
	}
}
