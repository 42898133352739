import { Controller } from '@hotwired/stimulus';
import { getComponent } from '@symfony/ux-live-component';

export default class extends Controller {
	static targets = ["mount", "cancelButton"];

	static values = {
		apiKey: String,
		language: String,
		paymentId: String,
		successPath: String,
	}

	checkout = null;

	component = null;

	async initialize() {
		this.component = await getComponent(this.element);
	}

	connect() {
		const containerId = this.mountTarget.id;

		const language = this.languageValue;

		this.checkout = new Dibs.Checkout({
			checkoutKey: this.apiKeyValue,
			paymentId: this.paymentIdValue,
			containerId: containerId,
			language: language,
			theme: {
				backgroundColor: "#f8f9fa",
				buttonRadius: "0.375rem",
			},
		});

		this.cancelButtonTarget.addEventListener('click', () => {
			this.freeze();
		});

		this.checkout.on('payment-completed', () => {
			this.cancelButtonTarget.removeAttribute("href");
			window.location.replace(this.successPathValue);
		});

		const validate = () => {
			this.component.action('validate')
				.then(({ response }) => {
					if (!response.redirected) {
						this.checkout.send('payment-order-finalized', true);
					}
				});
		};

		this.checkout.on('pay-initialized', validate);
	}

	freeze() {
		if (!this.checkout) {
			return;
		}

		this.checkout.freezeCheckout();
	}
}
