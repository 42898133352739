import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	static values = {
		context: Object
	}

	brevo = null;
	disabled = false;

	connect() {
		this.brevo = window.BrevoConversations;
		if (!this.brevo) {
			console.log("brevo not available");
			this.disabled = true;
			return;
		}

		for (const [k, v] of Object.entries(this.contextValue)) {
			this.update(k, v);
		}
	}

	update(key, value) {
		if (this.disabled || !this.brevo) {
			console.log("brevo controller disabled or not available");
			return;
		}

		const update = {};
		update[key] = value;

		this.brevo('updateIntegrationData', {...update})
	}
}
